<template>
    <tr>
        <td class="px-6 py-4 whitespace-nowrap">
            <div class="flex items-center">
                <div class="flex-shrink-0 h-10 w-10">
                    <img class="h-10 w-10 rounded-full" :src="collaborator.user.avatarUrl" alt="">
                </div>
                <div class="ml-4">
                    <div class="text-sm font-medium text-gray-900">
                        {{ collaborator.user.firstName + " " + collaborator.user.lastName }}
                    </div>
                    <div class="text-sm text-gray-500">
                        @{{ collaborator.user.username }}
                    </div>
                </div>
            </div>
        </td>
<!--        <td class="px-6 py-4 whitespace-nowrap">-->
<!--            <div class="text-sm text-gray-900 cursor-pointer">{{ title }}</div>-->
<!--&lt;!&ndash;            <div class="text-sm text-gray-500">Optimization</div>&ndash;&gt;-->
<!--        </td>-->
        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
<!--            <p v-if="isGlobalAdmin">-->
<!--                Global Admin-->
<!--            </p>-->
            <div v-if="isGlobalAdmin" class="flex">
                <div class="flex shadow-sm rounded-md">
                    <div class="flex-1 flex items-center justify-between border-l rounded-l-md border-t border-r border-b border-gray-200 bg-jiruto-flatRed rounded-r-md truncate">
                        <div class="flex-1 flex px-3 py-2 text-sm leading-5 truncate">
                            <p class="text-white font-mediums transition ease-in-out duration-150">Global Admin</p>
                            <button @click="removeGlobalAdmin" class="flex text-white items-center ml-2 hover:text-gray-500">
                                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!isGlobalAdmin" class="flex flex-wrap">
                <!-- This example requires Tailwind CSS v2.0+ -->
                <!--
                  Custom select controls like this require a considerable amount of JS to implement from scratch. We're planning
                  to build some low-level libraries to make this easier with popular frameworks like React, Vue, and even Alpine.js
                  in the near future, but in the mean time we recommend these reference guides when building your implementation:

                  https://www.w3.org/TR/wai-aria-practices/#Listbox
                  https://www.w3.org/TR/wai-aria-practices/examples/listbox/listbox-collapsible.html
                -->
                <div v-if="remainingIntegrations.length !== 0">
                    <div class="mt-1 relative">
                        <button @click="toggleIntegrationsMenu" type="button" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label" class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                            <span class="block truncate">
                                Choose Integrations
                            </span>
                            <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <!-- Heroicon name: solid/selector -->
                                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                  <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </span>
                        </button>

                        <transition enter-class="transform opacity-0 scale-95"
                                    enter-active-class="transition ease-out duration-100"
                                    enter-to-class="transform opacity-100 scale-100"
                                    leave-class="transform opacity-100 scale-100"
                                    leave-active-class="transition ease-in duration-75"
                                    leave-to-class="transform opacity-0 scale-95"
                        >
                            <div v-on-clickaway="closeIntegrationsMenu" v-if="showIntegrationsMenu" class="absolute mt-1 w-full rounded-md bg-white shadow-lg z-50">
                                <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-item-3" class="max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                    <!--
                                      Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.

                                      Highlighted: "text-white bg-indigo-600", Not Highlighted: "text-gray-900"
                                    -->
                                    <li v-for="integration in remainingIntegrations" :key="integration.name" @click="integrationSelected(integration)" role="option" class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 hover:text-white hover:bg-indigo-600">
                                        <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                                        <span class="font-normal block truncate">
                                            {{ integration.title }}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </transition>
                    </div>
                </div>


                <div class="mt-1 ml-3 flex space-x-2 flex-wrap items-center">
                    <div v-for="integration in collaborator.integrations" :key="integration.name" class="">
                        <div class="flex shadow-sm rounded-md">
                            <div class="flex-1 flex items-center justify-between border-l rounded-l-md border-t border-r border-b border-gray-200 bg-purple-100 rounded-r-md truncate">
                                <div class="flex-1 flex px-3 py-2 text-sm leading-5 truncate">
                                    <p class="text-gray-900 font-medium hover:text-gray-600 transition ease-in-out duration-150">{{ integration.title }}</p>
                                    <button @click="removeIntegration(integration.name)" class="flex items-center ml-2 hover:text-gray-400">
                                        <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </td>
        <td class="pr-6">
            <div class="relative flex justify-end items-center">
                <button @click="menuButtonPressed" id="project-options-menu-0" aria-haspopup="true" type="button" class="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                    <span class="sr-only">Open options</span>
                    <!-- Heroicon name: solid/dots-vertical -->
                    <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                    </svg>
                </button>
                <!--
                  Dropdown panel, show/hide based on dropdown state.

                  Entering: "transition ease-out duration-100"
                    From: "transform opacity-0 scale-95"
                    To: "transform opacity-100 scale-100"
                  Leaving: "transition ease-in duration-75"
                    From: "transform opacity-100 scale-100"
                    To: "transform opacity-0 scale-95"
                -->
                <transition enter-class="transform opacity-0 scale-95"
                            enter-active-class="transition ease-out duration-100"
                            enter-to-class="transform opacity-100 scale-100"
                            leave-class="transform opacity-100 scale-100"
                            leave-active-class="transition ease-in duration-75"
                            leave-to-class="transform opacity-0 scale-95"
                >
                    <div v-on-clickaway="closeOptionsMenu" v-if="showOptionsMenu" class="mx-3 origin-top-right absolute right-7 top-0 w-48 mt-1 rounded-md shadow-lg z-10 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200" role="menu" aria-orientation="vertical" aria-labelledby="project-options-menu-0">
                        <div class="py-1" role="none">
<!--                            <a href="#" class="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">-->
<!--                                &lt;!&ndash; Heroicon name: solid/pencil-alt &ndash;&gt;-->
<!--                                <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">-->
<!--                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />-->
<!--                                    <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" />-->
<!--                                </svg>-->
<!--                                Edit-->
<!--                            </a>-->
                            <button @click="stripRoles" class="group flex w-full items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                                <!-- Heroicon name: solid/duplicate -->
                                <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z" />
                                    <path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" />
                                </svg>
                                Strip Roles
                            </button>
                            <button v-if="isGlobalAdmin" @click="removeGlobalAdmin" class="group flex w-full items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                                <!-- Heroicon name: solid/user-add -->
                                <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 004.083 9zM10 2a8 8 0 100 16 8 8 0 000-16zm0 2c-.076 0-.232.032-.465.262-.238.234-.497.623-.737 1.182-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.004 6.004 0 0115.917 9h-1.946zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556.24.56.5.948.737 1.182.233.23.389.262.465.262.076 0 .232-.032.465-.262.238-.234.498-.623.737-1.182.389-.907.673-2.142.766-3.556zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.004 6.004 0 01-2.783 4.118zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0 002.783 4.118z" clip-rule="evenodd" />
                                </svg>
                                Remove Admin
                            </button>
                            <button v-else @click="addGlobalAdmin" class="group flex w-full items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                                <!-- Heroicon name: solid/user-add -->
                                <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 004.083 9zM10 2a8 8 0 100 16 8 8 0 000-16zm0 2c-.076 0-.232.032-.465.262-.238.234-.497.623-.737 1.182-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.004 6.004 0 0115.917 9h-1.946zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556.24.56.5.948.737 1.182.233.23.389.262.465.262.076 0 .232-.032.465-.262.238-.234.498-.623.737-1.182.389-.907.673-2.142.766-3.556zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.004 6.004 0 01-2.783 4.118zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0 002.783 4.118z" clip-rule="evenodd" />
                                </svg>
                                Make Global Admin
                            </button>
                        </div>
                        <div class="py-1" role="none">
                            <button @click="removeCollaborator" class="group flex w-full items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                                <!-- Heroicon name: solid/trash -->
                                <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                                </svg>
                                Remove User
                            </button>
                        </div>
                    </div>
                </transition>
            </div>
        </td>
    </tr>
</template>

<script>
    import {directive as onClickaway} from 'vue-clickaway'
    import {mapState} from 'vuex';
    import * as axios from '@/axios-auth'
    import * as _ from 'lodash'
    export default {
        name: "CollaboratorCard",
        components: {},
        directives: {
            onClickaway,
        },
        props: {
            collaboratorProp: {
                roles: [],
                integrations: [],
                isGlobalAdmin: false,
            },
            communityOwner: {
                type: String,
                required: true,
            },
            communityName: {
                type: String,
                required: true,
            },
        },
        data(){
            return {
                collaborator: {
                    integrations: [],
                    isGlobalAdmin: false,
                    user: null,
                },
                showOptionsMenu: false,
                showIntegrationsMenu: false,
            }
        },
        created() {
            this.initializeCollaborator()
        },
        watch: {
            collaboratorProp(){
                this.initializeCollaborator()
            },
            remainingIntegrations(){
                if(this.remainingIntegrations.length === 0) {
                    this.showIntegrationsMenu = false
                }
            }
        },
        computed: {
            ...mapState({
                integrations: state => state.community.integrations
            }),
            title(){
                if (this.collaborator.title === null || this.collaborator.title === undefined){
                    return "Edit title"
                }
                return this.collaborator.title
            },
            role(){
                if (this.collaborator.roles === [] || this.collaborator.roles === null || this.collaborator.roles === undefined){
                    return "No Roles"
                }
                return "Global Admin"
            },
            isGlobalAdmin(){
                return !!this.collaborator.isGlobalAdmin
            },
            remainingIntegrations(){
                return _.filter(this.integrations, (integration) => {
                    return !this.isAdminForIntegration(integration.name)
                })
            }
        },
        methods: {
            stripRoles(){
                this.showOptionsMenu = false
                const currentCollaborator = _.cloneDeep(this.collaborator)
                this.collaborator.isGlobalAdmin = false
                this.collaborator.integrations = []
                axios.jigo.delete(`/v2/community/removeAllPrivileges/${this.communityOwner}/${this.communityName}/${this.collaborator.user.JID}`)
                .then((resp) => {
                    if(resp.status !== 200) {
                        console.log("Unwanted status: ", resp.status, resp)
                        this.collaborator = currentCollaborator
                    }
                    this.collaborators = resp.data
                })
                .catch((err) => {
                    this.collaborator = currentCollaborator
                    console.error("ERROR: addGlobalAdmin FAILED", err)
                })
            },
            removeCollaborator(){
                this.showOptionsMenu = false
                this.$emit("removeCollaborator", this.collaborator)
            },
            addGlobalAdmin(){
                this.collaborator.isGlobalAdmin = true
                axios.jigo.put(`/v2/community/addGlobalAdmin/${this.communityOwner}/${this.communityName}/${this.collaborator.user.JID}`)
                .then((resp) => {
                    if(resp.status !== 200) {
                        console.log("Unwanted status: ", resp.status, resp)
                        this.collaborator.isGlobalAdmin = false
                    }
                    this.collaborators = resp.data
                })
                .catch((err) => {
                    this.collaborator.isGlobalAdmin = false
                    console.error("ERROR: addGlobalAdmin FAILED", err)
                })
            },
            removeGlobalAdmin(){
                this.collaborator.isGlobalAdmin = false
                axios.jigo.delete(`/v2/community/removeGlobalAdmin/${this.communityOwner}/${this.communityName}/${this.collaborator.user.JID}`)
                .then((resp) => {
                    if(resp.status !== 200) {
                        console.log("Unwanted status: ", resp.status, resp)
                        this.collaborator.isGlobalAdmin = true
                    }
                    this.collaborators = resp.data
                })
                .catch((err) => {
                    this.collaborator.isGlobalAdmin = true
                    console.error("ERROR: revokeGlobalAdmin FAILED", err)
                })
            },
            removeIntegration(integrationName){
                let integration = this.removeIntegrationLocally(integrationName)
                axios.jigo.delete(`/v2/community/removeIntegrationAdmin/${this.communityOwner}/${this.communityName}/${integrationName}/${this.collaborator.user.JID}`)
                .then((resp) => {
                    if(resp.status !== 200) {
                        console.log("Unwanted status: ", resp.status, resp)
                        this.addIntegrationLocally(integration)
                    }
                    this.collaborators = resp.data
                })
                .catch((err) => {
                    this.addIntegrationLocally(integration.name)
                    console.error("ERROR: removeIntegration FAILED", err)
                })
            },
            initializeCollaborator(){
                this.collaborator = this.collaboratorProp
                if (this.collaborator.integrations === null || this.collaborator.integrations === undefined) {
                    this.collaborator.integrations = []
                }
            },
            toggleIntegrationsMenu(){
                this.showIntegrationsMenu = !this.showIntegrationsMenu
            },
            closeIntegrationsMenu(){
                this.showIntegrationsMenu = false
            },
            isAdminForIntegration(integrationName){
                let index =  _.findIndex(this.collaborator.integrations, (integration) => {
                    return integration.name === integrationName
                });
                return index !== -1
            },
            integrationSelected(integration){
                this.addIntegrationLocally(integration)
                axios.jigo.put(`/v2/community/addIntegrationAdmin/${this.communityOwner}/${this.communityName}/${integration.name}/${this.collaborator.user.JID}`)
                .then((resp) => {
                    if(resp.status !== 200) {
                        console.log("Unwanted status: ", resp.status, resp)
                        this.removeIntegrationLocally(integration.name)
                    }
                })
                .catch((err) => {
                    this.removeIntegrationLocally(integration.name)
                    console.error("ERROR: integrationSelected FAILED", err)
                })
            },
            addIntegrationLocally(integration){
                this.collaborator.integrations.push(integration)
            },
            removeIntegrationLocally(integrationName){
                let index =  _.findIndex(this.collaborator.integrations, (integration) => {
                    return integration.name === integrationName
                });
                return this.collaborator.integrations.splice(index, 1)
            },
            menuButtonPressed(){
                this.showOptionsMenu = !this.showOptionsMenu
            },
            closeOptionsMenu(){
                this.showOptionsMenu = false
            }
        }
    }
</script>

<style scoped>
    .flip-horizontally {
        transform: scaleX(-1);
    }

</style>
