<template>
    <div class="bg-gray-100 shadow-xl-white px-4 py-5 sm:rounded-lg sm:p-6">
        <div class="space-y-6 sm:space-y-5">
            <div class="mt-5 sm:mt-1">
                <div class="">
                    <label for="searchSubscribers" class="sr-only">Search community subscribers</label>
                    <div class="relative rounded-md shadow-sm sm:max-w-xs">
                        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <!-- Heroicon name: search -->
                            <svg class="mr-3 h-4 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                            </svg>
                        </div>
                        <input type="text" autocomplete="off" v-model="subscribersSearchTerm" id="searchSubscribers" @focus="searchSubscribers" @input="searchSubscribers"
                               class="h-8 border pl-9 rounded-lg focus:outline-none block border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 border w-full rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5 " placeholder="Search community subscribers">
                    </div>
                    <div v-if="showPresentersSelection && subscribersList.length > 0" class="sm:max-w-xs relative mt-5">
                        <userSelection :users="subscribersList" @close="showPresentersSelection = false"
                                       @userSelected="addCollaborator" :open="showPresentersSelection" class="absolute w-full"/>
                    </div>
                </div>
            </div>

            <!-- This example requires Tailwind CSS v2.0+ -->
            <div class="flex flex-col">
                <div class="-my-2 sm:-mx-6 lg:-mx-8">
                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div class="shadow border-b border-gray-200 sm:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-200">
                                <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Name
                                    </th>
<!--                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">-->
<!--                                        Title-->
<!--                                    </th>-->
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Role
                                    </th>
                                    <th scope="col" class="relative px-6 py-3">
                                        <span class="sr-only">Options</span>
                                    </th>
                                </tr>
                                </thead>
                                <tbody v-if="!collaboratorsLoading" class="bg-white divide-y divide-gray-200">
                                <collaborator-card :community-owner="communityOwner" :community-name="communityName" @removeCollaborator="removeCollaborator"
                                                   v-for="collaborator in collaborators" :key="collaborator.JID" :collaboratorProp="collaborator"></collaborator-card>


                                <!-- More items... -->
                                </tbody>
                                <jiruto-table-row-loader v-if="collaboratorsLoading"></jiruto-table-row-loader>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
    import * as axios from '@/axios-auth'
    import * as _ from 'lodash'
    import UserSelection from "@/components/UserSelection";
    import CollaboratorCard from "@/pages/communityPage/components/CollaboratorCard";
    import JirutoTableRowLoader from "@/components/JirutoTableRowLoader";
    export default {
        name: "CommunitySettingsCollaborators",
        components: {JirutoTableRowLoader, CollaboratorCard, UserSelection},
        props: {
            communityOwner: {
                type: String,
                required: true,
            },
            communityName: {
                type: String,
                required: true,
            },
        },
        created(){
            this.fetchCollaborators()
        },
        computed: {
            communityNameForUrl() {
                return this.communityName.replace(/\s/g, "-")
            },

        },
        data(){
            return {
                collaboratorsLoading: false,
                subscribersSearchTerm: "",
                showPresentersSelection: false,
                subscribersList: [],
                collaborators: [],
            }
        },
        methods: {
            searchSubscribers: _.debounce(function ()  {
                if(this.subscribersSearchTerm.trim() === "") {
                    return
                }
                axios.jigo.get(`/v2/community/searchSubscribers/${this.communityOwner}/${this.communityNameForUrl}`, {params: {q: this.subscribersSearchTerm.trim()}})
                .then((resp) => {
                    if(resp.status === 200) {
                        this.subscribersList = resp.data
                        this.showPresentersSelection = true
                    } else {
                        console.error("ERROR: unwanted status returned", resp.status)
                    }
                })
                .catch((err) => {
                    console.error("ERROR: searchSubscribers FAILED", err)
                })
            }, 250),
            fetchCollaborators(){
                this.collaboratorsLoading = true
                axios.jigo.get(`/v2/community/getAllCollaborators/${this.communityOwner}/${this.communityName}`)
                .then((resp) => {
                    if(resp.status !== 200) {
                        console.log("Unwanted status: ", resp.status, resp)
                    }
                    this.collaboratorsLoading = false
                    console.log("Collaborators:", resp.data)
                    this.collaborators = resp.data
                })
                .catch((err) => {
                    this.collaboratorsLoading = false
                    console.error("ERROR: fetchCollaborators FAILED", err)
                })
            },
            addCollaborator(user){
                const collaborator = {
                    user: user,
                    integrations: [],
                }
                this.addCollaboratorLocally(collaborator)
                axios.jigo.put(`/v2/community/addCollaborator/${this.communityOwner}/${this.communityName}/${user.JID}`)
                .then((resp) => {
                    if(resp.status !== 200) {
                        console.log("Unwanted status: ", resp.status, resp)
                        this.removeCollaboratorLocally(collaborator.JID)
                    }
                })
                .catch((err) => {
                    this.removeCollaboratorLocally(collaborator.JID)
                    console.error("ERROR: addCollaborator FAILED", err)
                })
            },
            removeCollaborator(collaborator){
                console.log("Collaborator: ", collaborator)
                const collaboratorID = collaborator.user.JID
                this.removeCollaboratorLocally(collaboratorID)
                axios.jigo.delete(`/v2/community/removeCollaborator/${this.communityOwner}/${this.communityName}/${collaboratorID}`)
                .then((resp) => {
                    if(resp.status !== 200) {
                        console.log("Unwanted status: ", resp.status, resp)
                        this.addCollaboratorLocally(collaborator)
                    }
                })
                .catch((err) => {
                    this.addCollaboratorLocally(collaborator)
                    console.error("ERROR: removeCollaborator FAILED", err)
                })
            },

            addCollaboratorLocally(collaborator){
                this.collaborators.push(collaborator)
            },
            removeCollaboratorLocally(collaboratorID){
                const index =  _.findIndex(this.collaborators, (collaborator) => {
                    return collaborator.user.JID === collaboratorID
                });
                if (index === -1) { return }
                return this.collaborators.splice(index, 1)[0]
            },

        }
    }
</script>

<style scoped>

</style>
